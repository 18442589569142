<template>
  <div id="app">
    <!-- Preloader -->
    <div v-if="isLoading" class="preloader"></div>
    <!-- 主要内容 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isLoading: true,  // 默认显示预加载动画
    };
  },
  mounted() {
    // 页面加载完成后，延时隐藏预加载动画
    this.handlePreloader();
  },
  methods: {
    reload() {
      // 刷新当前页面
      this.$router.go(0);
    },
    handlePreloader() {
      // 延迟 200 毫秒后开始隐藏预加载动画，模拟加载完成
      setTimeout(() => {
        this.isLoading = false;  // 隐藏预加载动画
      }, 200);  // 可根据实际情况调整延迟
    }
  },
}
</script>